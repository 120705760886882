import { ReactComponent as PoweredByHyveIcon } from '../assets/icons/powered-by-hyve.svg';
import backQrImg from '../assets/qr/back.png';
import completeStepQrImg from '../assets/qr/complete-step.png';
import { useScannerInput } from '../hooks/use-scanner-input';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import styles from './styles.module.css';

export const MAFooter = ({ onGoBack, submitLabel, onSubmit }: { onGoBack?: () => void; submitLabel?: string; onSubmit?: () => void }) => {
  const processFooterInput = (input: string) => {
    switch (input) {
      case '$hyve_back$':
        onGoBack?.();
        break;
      case '$hyve_complete-step$':
        onSubmit?.();
        break;
      default:
        break;
    }
  };

  useScannerInput(processFooterInput);

  return (
    <footer className={styles.footer}>
      <div className={styles.closeBtn} style={{ opacity: onGoBack ? 1 : 0 }} onClick={onGoBack}>
        <ArrowLeftIcon />
        <img className={styles.closeQr} src={backQrImg} width={48} height={48} alt='Back' />
      </div>
      <div className={styles.centerLogo}>
        <PoweredByHyveIcon />
      </div>
      <div className={styles.submitBtnWrapper}>
        <div className={styles.submitBtn} style={{ opacity: submitLabel ? 1 : 0 }} onClick={onSubmit}>
          {submitLabel}
          <img src={completeStepQrImg} width={48} height={48} alt='Submit' />
        </div>
      </div>
    </footer>
  );
};
