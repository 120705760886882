import { useNavigate } from 'react-router-dom';
import { useScannerInput } from '../hooks/use-scanner-input';
import { ReactComponent as MaIomLogoIcon } from '../assets/icons/ma-iom-logo.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import exitQrImg from '../assets/qr/exit.png';
import styles from './styles.module.css';

export const MAHeader = () => {
  const navigate = useNavigate();

  const onExit = () => navigate('/product-models');

  const processHeaderInput = (input: string) => {
    switch (input) {
      case '$hyve_exit$':
        onExit();
        break;
      default:
        break;
    }
  };

  useScannerInput(processHeaderInput);

  return (
    <header className={styles.header}>
      <div>
        <MaIomLogoIcon />
      </div>
      <div className={styles.closeBtn} onClick={onExit}>
        <img className={styles.exitQr} src={exitQrImg} width={48} height={48} alt='exit' />
        <CloseIcon />
      </div>
    </header>
  );
};
