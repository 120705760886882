import { Route, Routes } from 'react-router-dom';
import { MAHeader } from "./components/MAHeader";
import { ActivatePage } from "./pages/activate/ActivatePage";
import { ContinueExistingPage } from "./pages/continue-existing/ContinueExistingPage";
import { HomePage } from "./pages/home/HomePage";
import styles from './styles.module.css';

export const ManufacturingActivationPage = () => {
  return (
    <div className={styles.container}>
      <MAHeader />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/activate/*' element={<ActivatePage />} />
        <Route path='/continue-existing/*' element={<ContinueExistingPage />} />
      </Routes>
    </div>
  );
};
