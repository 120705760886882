import { useEffect, useState } from 'react';
import { apiClient } from '../../../../../../api/apiClient';
import { useScannerInput } from '../../../../hooks/use-scanner-input';
import styles from './styles.module.css';

export const CustomerStep = ({ goToNextStep }: { goToNextStep: (data: any, isReplace?: boolean) => void }) => {
  const [customers, setCustomers] = useState<any[]>([]);

  const getCustomers = async () => {
    const res = await apiClient.get('/customer/getActivation');
    if (res?.data?.length === 1) {
      goToNextStep({ customer: res?.data[0] }, true);
    } else {
      setCustomers(res?.data ?? []);
    }
  }

  const onSelectCustomer = (customer: any) => {
    goToNextStep({ customer });
  };

  const processCustomerInput = (input: string) => {
    if (input.startsWith('$')) return;

    const customer = customers.find(c => c.name === input);
    onSelectCustomer(customer);
  };

  useScannerInput(processCustomerInput);

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.customers}>
      {customers.map(customer => (
        <div key={customer.id} className={styles.customer} onClick={() => onSelectCustomer(customer)}>
          <img src={customer.qr} alt={customer.name} />
          <div>{customer.name}</div>
        </div>
      ))}
    </div>
  );
};
