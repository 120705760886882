import { useEffect } from 'react';

export const useScannerInput = (onInput?: (text: string) => void) => {
  useEffect(() => {
    if (!onInput) {
      return;
    }

    let inputText = ''
    const inputListener = (e: any) => {
      const keyText = e.key;
      const alphaRegex = /[a-zA-Z0-9_ \-$]/
      if ((keyText.length === 1 || keyText === 'Enter') && alphaRegex.test(keyText)) {
        inputText += keyText;
        if (inputText.endsWith('Enter010')) {
          const finalText = inputText.replace('Enter010', '');
          setTimeout(() => onInput(finalText), 100);
          inputText = '';
        }
      }
    };
    window.addEventListener('keydown', inputListener);

    return () => window.removeEventListener('keydown', inputListener);
  }, [onInput]);
};
