import { gql } from '@apollo/client';

export const AssetsListQuery = gql`
    query GetAssets($page: Int, $search: String) {
        assets(pagination: { page: $page, pageSize: 50 }, filters: { and: [{ or: [ { name: { contains: $search } }, { product: { name: { contains: $search } } }, { serialNumber: { contains: $search } }] }, { name: { ne: "" } } ]}) {
            meta {
                pagination {
                    page
                    pageCount
                    total
                }
            }
            data {
                id
                attributes {
                    assetId
                    name
                    serialNumber
                    IsInstallationRequired
                    locationInstallationDate
                    kitchenArea
                    manufacturingDate
                    images {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    product {
                        data {
                            id
                            attributes {
                                name
                                productID
                                build {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                manufacturer {
                                    data {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DeleteAssetQuery = gql`
    mutation DeleteAsset($id: ID!) {
        deleteAsset(id: $id) {
            data {
                id
            }
        }
    }
`;
