import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DeletePartQuery, PartsListQuery } from './query';
import { CreatePartModal } from './CreatePartModal';
import { PlusFloatingButton } from '../../components/PlusFloatingButton';
import { RemovalConfirmationModal } from '../../components/RemovalConfirmationModal';
import Table from '../../components/Table/Table';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
// import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
// import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as PartIcon } from '../../assets/icons/part.svg';
import styles from './styles.module.css';

export const PartsPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [editModalData, setEditModalData] = useState<Record<string, string>>();
  const [promptToRemoveId, setPromptToRemoveId] = useState<string>();
  const { data, loading, refetch } = useQuery(PartsListQuery, {
    variables: { page, search },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const [deletePart] = useMutation(DeletePartQuery);

  const rows = useMemo(
    () =>
      (data?.partModels?.data || []).map((partModel: any) => ({
        id: partModel.id,
        name: partModel.attributes?.name,
        description: partModel.attributes?.description,
        number: partModel.attributes?.partNumber,
        image: partModel.attributes?.images?.data?.[0]?.attributes?.url,
        category: partModel.attributes?.known_part_model?.data?.attributes?.category || '—',
        status: '—',
      })),
    [data]
  );

  /* const onEdit = (params: any) => {
    const entityData = rows?.find((row: any) => row.id === params.data.id);
    setEditModalData({ type: 'edit', ...(entityData || {}) });
  }; */

  const onDelete = async (id: any) => {
    await deletePart({
      variables: { id },
    });
    refetch?.();
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={loading}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            onClick={(e: any) => {
              if (e.colDef?.field === 'actions') return;
              navigate(`/part/${e.data.id}`);
            }}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate(-1)}>
                  <BackArrowIcon />
                </button>
                <h1>Parts</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Part Name',
                flex: 1,
                minWidth: 326,
                cellRenderer: (params: { data: { image: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <PartIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
                pinned: 'left'
              },
              {
                field: 'number',
                headerName: 'ID',
                minWidth: 200,
                flex: 1,
                filterName: 'number',
              },
              {
                field: 'category',
                headerName: 'Category',
                width: 200,
                filterName: 'category',
              },
              {
                field: 'status',
                headerName: 'Status',
                width: 200,
                filterName: 'status',
              },
              {
                field: 'actions',
                headerName: '',
                width: 120,
                filterName: 'actions',
                cellRenderer: (/*params: any*/) => (
                  <div className={styles.actionsField}>
                    {/*<EditIcon onClick={() => onEdit(params)} />
                    <CloseIcon onClick={() => setPromptToRemoveId(params.data.id)} />*/}
                  </div>
                ),
              },
            ]}
            filters={filters}
            rows={rows}
            availableFilters={[]}
            paginationData={data?.partModels?.meta?.pagination}
            onChangePage={setPage as any}
            hideFilters
          />
          <PlusFloatingButton onClick={() => setEditModalData({ type: 'new' })} />
          {!!editModalData && <CreatePartModal editModalData={editModalData} onClose={() => setEditModalData(undefined)} />}
          <RemovalConfirmationModal
            promptToRemoveId={promptToRemoveId}
            entityLabel='Part'
            onRemove={onDelete}
            onClose={() => setPromptToRemoveId(undefined)}
          />
        </div>
      </div>
    </div>
  );
};
