import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from "../components/PrivateRoute";
import { PublicOnlyRoute } from "../components/PublicOnlyRoute";
import { SignInPage } from "../pages/sign-in/SignInPage";
import { SignUpPage } from "../pages/sign-up/SignUpPage";
import { NameStep } from "../pages/sign-up/components/NameStep";
import { PhotoStep } from "../pages/sign-up/components/PhotoStep";
import { EmailStep } from "../pages/sign-up/components/EmailStep";
import { EmailVerifyStep } from "../pages/sign-up/components/EmailVerifyStep";
/* import { PhoneStep } from "../pages/sign-up/components/PhoneStep";
import { PhoneVerifyStep } from "../pages/sign-up/components/PhoneVerifyStep"; */
import { SuccessStep } from "../pages/sign-up/components/SuccessStep";
import { Home } from "../pages/home/Home";
import { ProfilePage } from "../pages/profile/ProfilePage";
import { ProductModelsPage } from "../pages/product-models/ProductModelsPage";
import { ProductModelDetailsPage } from "../pages/product-model-details/ProductModelDetailsPage";
import { OrganizationsPage } from "../pages/organizations/OrganizationsPage";
import { OrganizationSettingsPage } from "../pages/organization-settings/OrganizationSettingsPage";
import { OrganizationDetailsPage } from "../pages/organization-details/OrganizationDetailsPage";
import { AssetsPage } from "../pages/assets/AssetsPage";
import { AssetDetailsPage } from "../pages/asset-details/AssetDetailsPage";
import { ComponentsListPage } from "../pages/components/ComponentsPage";
import { ComponentDetailsPage } from "../pages/component-details/ComponentDetailsPage";
import { ManufacturingActivationPage } from "../pages/manufacturing-activation/ManufacturingActivationPage";
import { PartsPage } from "../pages/parts/PartsPage";
import { PartDetailsPage } from "../pages/part-details/PartDetailsPage";

export const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<PublicOnlyRoute />}>
        <Route path='/sign-in'>
          <Route path='/sign-in/email' element={<SignInPage key='email' />} />
          <Route path='/sign-in/phone' element={<SignInPage isSms key='phone' />} />
          <Route index element={<Navigate to='/sign-in/email' replace />} />
        </Route>
        <Route path='/sign-up' element={<SignUpPage />}>
          <Route path='/sign-up' element={<NameStep />} />
          <Route path='/sign-up/photo' element={<PhotoStep />} />
          <Route path='/sign-up/email' element={<EmailStep />} />
          <Route path='/sign-up/email/verify' element={<EmailVerifyStep />} />
          {/* <Route path='/sign-up/phone' element={<PhoneStep />} />
          <Route path='/sign-up/phone/verify' element={<PhoneVerifyStep />} /> */}
          <Route path='/sign-up/success' element={<SuccessStep />} />
        </Route>
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/' element={<Home />} />
        <Route path='/profile' element={<ProfilePage />} />
        <Route path='/product-models' element={<ProductModelsPage />} />
        <Route path='/assets' element={<AssetsPage />} />
        <Route path='/asset/:id' element={<AssetDetailsPage />} />
        <Route path='/components' element={<ComponentsListPage />} />
        <Route path='/component/:id' element={<ComponentDetailsPage />} />
        <Route path='/product-model/:id' element={<ProductModelDetailsPage />} />
        <Route path='/organizations' element={<OrganizationsPage />} />
        <Route path='/organization/settings' element={<OrganizationSettingsPage />} />
        <Route path='/organization/:id' element={<OrganizationDetailsPage />} />
        <Route path='/parts' element={<PartsPage />} />
        <Route path='/part/:id' element={<PartDetailsPage />} />
        <Route path='/manufacturing-activation/*' element={<ManufacturingActivationPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
