import { useState, ChangeEvent, KeyboardEvent } from 'react';
import { apiClient } from '../../../../../../api/apiClient';
import styles from './styles.module.css';

export const SerialStep = ({ assetData, goToNextStep }: { assetData?: any; goToNextStep: (data: any) => void }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(''/*'12121280011111'*/);
  const [errorMessage, setErrorMessage] = useState('');

  const onCheckNumber = async () => {
    try {
      setIsSubmitting(true);
      await apiClient.post(`/asset/validateActivation/${value}`);
      await apiClient.post('/asset/create', {
        customerId: assetData.customerId,
        productName: assetData.modelNumber,
        serialNumber: value,
      });
      goToNextStep({ serialNumber: value });
    } catch (e) {
      setValue('');
      setErrorMessage((e as any)?.response?.data.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCheckNumber();
    }
  };

  return (
    <div>
      <input
        className={`${styles.input} ${!!errorMessage && styles.errorInput}`}
        onKeyDown={onKeyDown}
        value={value}
        disabled={isSubmitting}
        onChange={onChange}
        placeholder='Enter serial number'
        key={errorMessage}
        autoFocus
      />
      <div className={styles.error}>
        {errorMessage}
      </div>
    </div>
  );
};
