import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as AssetIcon } from '../../assets/icons/asset.svg';
import { ReactComponent as OrganizationsIcon } from '../../assets/icons/menus/organizations.svg';
import styles from './styles.module.css';
import { AssetDetailsQuery } from './query';
import { Tabs } from '../../components/Tabs';
import { Loader } from "../../components/Loader";
import { AssetNestedItemsTable } from "./AssetNestedItemsTable";
import { CreateAssetModal } from "../assets/CreateAssetModal";

export const AssetDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<string>('resources');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { data, loading } = useQuery(AssetDetailsQuery, {
    variables: { id },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const [editModalData, setEditModalData] = useState<Record<string, string>>();
  const asset = data?.asset?.data;

  /* const availableFilters = [
    { key: 'manufacturerName', label: 'Manufacturer', values: [{ key: 'Carrier', label: 'Carrier' }, { key: 'COOK', label: 'COOK' }, { key: 'DAYTON', label: 'DAYTON' }, { key: 'Halton', label: 'Halton' }] },
    { key: 'category', label: 'Category', values: [
        { key: 'Centerline Table, Cold', label: 'Centerline Table, Cold' },
        { key: 'Centerline Table, Hot', label: 'Centerline Table, Hot' },
        { key: 'Exhaust System', label: 'Exhaust System' },
        { key: 'Holding', label: 'Holding' },
        { key: 'HVAC', label: 'HVAC' },
        { key: 'Toaster, Bun', label: 'Toaster, Bun' },
        { key: 'Transfer Station/Stainless Cart', label: 'Transfer Station/Stainless Cart' },
        { key: 'Walk-in Cooler', label: 'Walk-in Cooler' },
        { key: 'Warmer', label: 'Warmer' },
    ]},
    { key: 'classification', label: 'Classification', values: [{ key: 'Cold Side', label: 'Cold Side' }, { key: 'Hot Side', label: 'Hot Side' }, { key: 'HVAC', label: 'HVAC' }, { key: 'Misc', label: 'Misc' }] },
  ]; */

  const assetDetails = useMemo(
    () => ({
      id,
      name: asset?.attributes.name,
      modelName: asset?.attributes.product?.data?.attributes.name,
      manufacturerId: asset?.attributes.product?.data?.attributes.manufacturer?.data?.id,
      productModelId: asset?.attributes.product?.data?.id,
      manufacturerName: asset?.attributes.product?.data?.attributes.manufacturer?.data?.attributes?.business_info?.data?.attributes?.name,
      description: asset?.attributes.product?.data?.attributes.description,
      image: asset?.attributes.images?.data?.[0]?.attributes.url || asset?.attributes.product?.data?.attributes?.images?.data?.[0]?.attributes.url,
      serialNumber: asset?.attributes.serialNumber,
      category: asset?.attributes.product?.data?.attributes?.product_category?.data?.attributes?.value || '–',
      manufacturingDate: asset?.attributes.manufacturingDate || '–',
      classification: asset?.attributes.product?.data?.attributes?.product_category?.data?.attributes?.product_classification?.data?.attributes?.value || '–',
      resources: asset?.attributes.product?.data?.attributes.resources?.data || [],
      components: asset?.attributes.component_assets?.data || [],
      parts: asset?.attributes.product?.data?.attributes.part_models?.data || [],
      isLinkedToLocation: !!asset?.attributes.location?.data?.attributes,
      locationImage: asset?.attributes.location?.data?.attributes?.images?.data?.[0]?.attributes.url,
      locationName: asset?.attributes.location?.data?.attributes?.name || '–',
      locationAddress: asset?.attributes.location?.data?.attributes?.fk || '–',
      isInstallationRequired: asset?.attributes.IsInstallationRequired || false,
      installationDate: asset?.attributes.locationInstallationDate || '',
      buildId: asset?.attributes.product?.data?.attributes?.build?.data?.[0]?.id,
      kitchenArea: asset?.attributes.kitchenArea || '',
    }),
    [asset, id],
  );

  const tabs = useMemo(() => {
    return [{
      key: 'resources',
      name: `Resources (${assetDetails?.resources?.length})`,
    }, {
      key: 'components',
      name: `Components (${assetDetails?.components?.length})`,
    }, {
      key: 'parts',
      name: `Model Parts (${assetDetails?.parts?.length})`,
    }];
  }, [assetDetails]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <button type="button" className={styles.backBtn} onClick={() => navigate('/assets')}>
              <BackArrowIcon />
            </button>
            <h1>{assetDetails.name}</h1>
            <button className={`export-btn ${styles.editBtn}`} onClick={() => {
              setEditModalData({ type: 'edit', ...(assetDetails as any || {}) });
            }}>
              Edit Asset
            </button>
          </div>
          <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
            <div className={styles.detailsCard}>
              {loading ? (<Loader isCentered />) : (
                <>
                  {assetDetails.image ? <img className={styles.detailsImg} src={assetDetails.image} alt="" /> : <AssetIcon className={styles.detailsImg} />}
                  <div style={{ flex: 1 }}>
                    <div className={styles.detailsInfoHeader}>
                      <span className={styles.name}>{assetDetails.manufacturerName} {assetDetails.modelName}</span>
                      <p className={styles.dimensions}>
                      </p>
                    </div>
                    <p className={styles.description}>
                      {assetDetails.description ? assetDetails.description.slice(0, isExpanded ? undefined : 200) : <em>No description</em>}
                      {assetDetails.description?.length > 200 ? <span className={styles.expandToggle} onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Hide details' : 'Show more'}</span> : null}
                    </p>
                    <div className={styles.detailsInfoBottom}>
                      <div className={styles.detailsParam}>
                        <p>Serial number</p>
                        <span>{assetDetails.serialNumber || '—'}</span>
                      </div>
                      <div className={styles.detailsParam}>
                        <p>Classification</p>
                        <span>{assetDetails.classification || '—'}</span>
                      </div>
                      <div className={styles.detailsParam}>
                        <p>Category</p>
                        <span>{assetDetails.category || '—'}</span>
                      </div>
                      <div className={styles.detailsParam}>
                        <p>Manufacturing date</p>
                        <span>{assetDetails.manufacturingDate || '—'}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {(loading || assetDetails.isLinkedToLocation) && (
              <div className={styles.locationCard}>
                {loading ? (<Loader isCentered />) : (
                  <>
                    {assetDetails.locationImage ? <img className={styles.detailsImg} src={assetDetails.locationImage} alt="" /> : <OrganizationsIcon className={styles.detailsImg} />}
                    <div style={{ flex: 1 }}>
                      <p className={styles.locationName}>
                        {assetDetails.locationName}
                      </p>
                      <p className={styles.locationAddress}>
                        {assetDetails.locationAddress}
                      </p>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {!!editModalData &&
            <CreateAssetModal editModalData={editModalData} onClose={() => setEditModalData(undefined)} />}
          <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            <Tabs tabs={tabs} currentTab={currentTab} onChange={setCurrentTab} />
            <AssetNestedItemsTable assetData={assetDetails} currentTab={currentTab} />
          </div>
        </div>
      </div>
    </div>
  );
};
