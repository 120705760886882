import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode';
import continueExistingQrImg from '../../assets/qr/continue-existing.png';
import { MAFooter } from '../../components/MAFooter';
import { useScannerInput } from '../../hooks/use-scanner-input';
import styles from './styles.module.css';

export const HomePage = () => {
  const navigate = useNavigate();
  const [startNewImage, setStartNewImage] = useState<string>()

  useEffect(() => {
    QRCode.toDataURL('$hyve_start-new$', { margin: 2, width: 580 }).then((img) => {
      setStartNewImage(img);
    });
  }, []);

  const onStartNew = () => navigate('/manufacturing-activation/activate?step=modelNumber');
  const onContinueExisting = () => navigate('/manufacturing-activation/continue-existing');

  const processHomeInput = (input: string) => {
    switch (input) {
      case '$hyve_start-new$':
        onStartNew();
        break;
      case '$hyve_continue-existing$':
        onContinueExisting();
        break;
      default:
        break;
    }
  };

  useScannerInput(processHomeInput);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Home</div>
      <div className={styles.actions}>
        <div onClick={onStartNew} className={`${styles.actionCard} ${styles.actionCardPrimary}`}>
          <img className={styles.qrImg} src={startNewImage} width={120} height={120} alt='Start new' />
          Start new
        </div>
        <div onClick={onContinueExisting} className={styles.actionCard}>
          <img className={styles.qrImg} src={continueExistingQrImg} width={120} height={120} alt='Continue existing' />
          Continue existing
        </div>
      </div>
      <MAFooter />
    </div>
  );
};
