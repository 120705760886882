import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoSmallIcon } from '../../assets/icons/logo-small.svg';
import { ReactComponent as CaretLeftIcon } from '../../assets/icons/caret-left.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/menus/profile.svg';
import { ReactComponent as ProductModelsIcon } from '../../assets/icons/menus/product-models.svg';
import { ReactComponent as AssetsIcon } from '../../assets/icons/asset.svg';
import { ReactComponent as PartsIcon } from '../../assets/icons/part.svg';
import { ReactComponent as ComponentsIcon } from '../../assets/icons/component.svg';
import { ReactComponent as OrganizationsIcon } from '../../assets/icons/menus/organizations.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/menus/settings.svg';
import styles from './styles.module.css';

const SIDEBAR_COLLAPSED_STORE_KEY = 'hyve.sidebar-collapsed';

export const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(localStorage.getItem(SIDEBAR_COLLAPSED_STORE_KEY) === 'true');

  const renderMenuItem = ({ label, link, Icon }: { label: string; link: string; Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }) => (
    <NavLink
      className={({ isActive }) =>
        `${isActive ? styles.linkActive : styles.link} ${isCollapsed ? styles.linkCollapsed : ''}`
      }
      to={link}
    >
      <Icon className={isCollapsed ? styles.linkCollapsedIcon : styles.linkIcon} />
      {isCollapsed ? '' : label}
    </NavLink>
  );

  const onChangeCollapsedState = (isCollapsed = false) => {
    setIsCollapsed(isCollapsed);
    localStorage.setItem(SIDEBAR_COLLAPSED_STORE_KEY, isCollapsed.toString());
  };

  return (
    <div className={isCollapsed ? styles.containerCollapsed : styles.container}>
      <div className={styles.topContainer}>
        {isCollapsed ? <LogoSmallIcon onClick={() => onChangeCollapsedState(!isCollapsed)} height='53px' style={{ marginLeft: '-8px' }} /> : <LogoIcon height='53px' />}
        {isCollapsed ? null : <CaretLeftIcon className={isCollapsed ? styles.menuCollapsedIcon : styles.menuIcon} onClick={() => onChangeCollapsedState(!isCollapsed)} />}
      </div>
      <div className={isCollapsed ? styles.categoriesCollapsed : styles.categories}>
        <div className={isCollapsed ? '' : styles.category}>
          {isCollapsed ? null : <div className={styles.categoryTitle}>Organization</div>}
          {renderMenuItem({ label: 'Settings', link: '/organization/settings', Icon: SettingsIcon })}
        </div>
        <div className={isCollapsed ? '' : styles.category}>
          {isCollapsed ? null : <div className={styles.categoryTitle}>User</div>}
          {renderMenuItem({ label: 'Profile', link: '/profile', Icon: ProfileIcon })}
          {renderMenuItem({ label: 'Organizations', link: '/organizations', Icon: OrganizationsIcon })}
          {renderMenuItem({ label: 'Product Models', link: '/product-models', Icon: ProductModelsIcon })}
          {renderMenuItem({ label: 'Assets', link: '/assets', Icon: AssetsIcon })}
          {renderMenuItem({ label: 'Parts', link: '/parts', Icon: PartsIcon })}
          {renderMenuItem({ label: 'Components', link: '/components', Icon: ComponentsIcon })}
          {renderMenuItem({ label: 'Manufacturing Activation', link: '/manufacturing-activation', Icon: ComponentsIcon })}
        </div>
      </div>
    </div>
  );
};
