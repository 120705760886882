import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import QRCode from 'qrcode';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import { ReactComponent as ComponentsIcon } from '../../../../assets/icons/components.svg';
import { ReactComponent as ExclamationIcon } from '../../../../assets/icons/exclamation.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useScannerInput } from '../../../../hooks/use-scanner-input';
import { ToastContent } from '../../../../../../components/ToastContent';
import styles from './styles.module.css';

export const ReviewStep = ({ assetData, componentsData, modelData, updateComponentsData }: { assetData?: any; componentsData?: any, modelData?: any; updateComponentsData: (data: any) => void }) => {
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState<string>();
  const [itemsQrs, setItemsQrs] = useState<string[]>([]);

  const connectedItems = componentsData?.items ?? [];

  const supportedPartModels = modelData?.supportedPartModels ?? [];
  const supportedPartModelsAmount = supportedPartModels?.length ?? 0;

  const supportedPartModelsWithState = supportedPartModels.map((pm: any) => ({
    ...pm,
    connectedState: connectedItems.find((connectedPm: any) => connectedPm.id === pm.id),
  }));

  const supportedPartModelsWithStateSorted = useMemo(() => {
    return [...supportedPartModelsWithState].sort((a: any, b: any) => a?.type.localeCompare(b?.type));
  }, [supportedPartModelsWithState]);

  const updateQrs = async () => {
    const qrs = await Promise.all(supportedPartModelsWithStateSorted.map((_: any, index: number) => {
      return QRCode.toDataURL(`$hyve_remove-item-${index}$`, { margin: 2, width: 580 });
    }));
    setItemsQrs(qrs);
  };

  useEffect(() => {
    updateQrs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemoveComponent = (componentTag: string) => {
    let updatedComponentsData = { items: [], ...(componentsData ?? {}) };
    const itemIndex = +componentTag.replace(/\D/g, '');

    if (updatedComponentsData?.items) {
      const itemToUpdateName = updatedComponentsData.items?.[itemIndex]?.name;
      const itemToUpdateType = updatedComponentsData.items?.[itemIndex]?.type;
      updatedComponentsData.items = updatedComponentsData.items.filter((_: any, index: number) => index !== itemIndex);
      updatedComponentsData.name = updatedComponentsData.items?.length ? `${updatedComponentsData.items?.length} items` : undefined;
      toast(<ToastContent title={`${itemToUpdateType} removed`} message={`Board ${itemToUpdateName} is successfully removed.`} />);
      updateComponentsData(updatedComponentsData);
    }
  };

  const processComponentsInput = (input: string) => {
    if (input.startsWith('$hyve_remove-item-')) {
      return setShowRemovalConfirmation(input);
    }
  };

  const getComponentNameByActionId = (actionId?: string) => {
    if (!actionId) {
      return '';
    }

    const itemIndex = +actionId.replace(/\D/g, '');
    return componentsData?.items?.[itemIndex]?.name;
  };

  useScannerInput(processComponentsInput);

  return (
    <div className={styles.container}>
      <ConfirmationModal
        isOpen={!!showRemovalConfirmation}
        title={getComponentNameByActionId(showRemovalConfirmation)}
        description="Are you sure you want to remove this component?"
        onConfirm={() => {
          showRemovalConfirmation && onRemoveComponent(showRemovalConfirmation);
          setShowRemovalConfirmation(undefined);
        }}
        onCancel={() => setShowRemovalConfirmation(undefined)}
      />
      <div className={styles.assetCard}>
        <div className={styles.assetList}>
          {[{ label: 'Model', value: assetData.modelNumber }, {
            label: 'Customer',
            value: assetData.customer
          }, { label: 'Serial Number', value: assetData.serialNumber }].map(({ label, value }) => (
            <div>
              <div className={styles.assetListItemLabel}>{label}:</div>
              <div className={styles.assetListItemValue}>{value}</div>
            </div>
          ))}
        </div>
      </div>
      <hr className={styles.assetSeparator} />
      <div className={styles.componentsBottomCards}>
        <div className={styles.componentsBottomCard}>
          <div className={styles.componentsBottomCardTitle}>
            Components
          </div>
          <div className={styles.componentsBottomCardSubtitle} style={{ color: connectedItems.length === supportedPartModelsAmount ? '#6FCF97' : '#F2994A' }}>
            {(connectedItems.length ?? 0)}/{supportedPartModelsAmount} Components
          </div>
          {supportedPartModelsWithStateSorted.map((pmWithState: any, index: number, arr: any[]) => (
            <div key={pmWithState.id + pmWithState.type} className={`${styles.item} ${pmWithState.connectedState ? styles.itemActive : ''}`}>
              {pmWithState.connectedState ? <CheckIcon width={24} height={24} /> : <ExclamationIcon />}
              <ComponentsIcon className={styles.itemTypeIcon} />
              <div>
                <p>{pmWithState.type} {index - arr.findIndex(pm => pm.type === pmWithState.type) + 1}</p>
                <p style={{fontSize: 16, color: '#F9F9F9'}}>{pmWithState.connectedState ? pmWithState.name : null}</p>
                <p style={{fontSize: 16, color: '#6E718A'}}>{pmWithState.connectedState?.serialNumber ? pmWithState.connectedState?.serialNumber : null}</p>
              </div>
              {pmWithState.connectedState ? <div className={styles.removeBtn} onClick={() => setShowRemovalConfirmation(`$hyve_remove-item-${index}$`)}>
                <TrashIcon />
                <img src={itemsQrs[index]} alt='' />
              </div> : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
