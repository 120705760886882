import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiClient } from '../../../../../api/apiClient';
import { ToastContent } from '../../../../../components/ToastContent';
import styles from './styles.module.css';

export const ActivateLabelPage = ({ modelData, componentsData, updateComponentsData }: { modelData: any; componentsData: any; updateComponentsData: (data: any) => void }) => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(''/*'0007wm5q'*/);
  const [errorMessage, setErrorMessage] = useState('');

  const onCheckNumber = async () => {
    try {
      setIsSubmitting(true);
      await apiClient.post(`/q-rcode/validateActivation/${value}`);
      toast(<ToastContent title='Recently added' message={`Label is successfully added`} />);
      const supportedPartModels = modelData?.supportedPartModels ?? [];
      const updatedItems = componentsData?.items || [];
      const foundedUnusedPartModel = supportedPartModels.find((pm: any) => updatedItems.every((i: any) => i.id !== pm.id));
      updatedItems.push({ id: foundedUnusedPartModel?.id, name: foundedUnusedPartModel?.name, serialNumber: value, type: 'Label' });
      updateComponentsData({ items: updatedItems, name: `${updatedItems.length} items` });
      navigate(-1);
    } catch (e) {
      setValue('');
      setErrorMessage((e as any)?.response?.data.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCheckNumber();
    }
  };

  const supportedPartModels = modelData?.supportedPartModels ?? [];
  const supportedLabelsAmount = supportedPartModels.filter((pm: any) => pm.type === 'Label')?.length ?? 0;
  const connectedLabelsAmount = (modelData?.items ?? []).filter((i: any) => i.type === 'Label')?.length ?? 0;

  const labelNumber = connectedLabelsAmount + 1;

  return (
    <div className={styles.container}>
      <div className={styles.headTitle}>Label {labelNumber} of {supportedLabelsAmount}</div>
      <div className={styles.content}>
        <div className={styles.title}>Scan QR Code Label</div>
        <div className={styles.description}>Scan QR code label or manually enter QR code label ID</div>
        <input
          className={`${styles.input} ${!!errorMessage && styles.errorInput}`}
          onKeyDown={onKeyDown}
          value={value}
          disabled={isSubmitting}
          onChange={onChange}
          placeholder='Enter QR code label ID'
          key={errorMessage}
          autoFocus
        />
        <div className={styles.error}>
          {errorMessage}
        </div>
      </div>
    </div>
  );
};
