import actionPrimaryQr from '../../assets/qr/action-primary.png';
import actionSecondaryQr from '../../assets/qr/action-secondary.png';
import { useScannerInput } from '../../hooks/use-scanner-input';
import styles from './styles.module.css';

export const ConfirmationModal = ({
  isOpen = false,
  title,
  description,
  onConfirm,
  onCancel,
}: any) => {
  const processSelectionInput = isOpen ? (input: string) => {
    switch (input) {
      case '$hyve_action-primary$':
        onConfirm();
        break;
      case '$hyve_action-secondary$':
        onCancel();
        break;
      default:
        break;
    }
  } : undefined;

  useScannerInput(processSelectionInput);

  if (!isOpen) return null;

  return (
    <div className={styles.backdrop}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
          <div className={styles.actions}>
            <div className={styles.actionPrimary} onClick={onCancel}>
              <img src={actionSecondaryQr} alt=''/>
              <p>Cancel</p>
            </div>
            <div className={styles.actionSecondary} onClick={onConfirm}>
              <img src={actionPrimaryQr} alt='' />
              <p>Remove</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
