import { gql } from '@apollo/client';

export const AssetDetailsQuery = gql`
    query GetAsset($id: ID) {
        asset(id: $id) {
            data {
                id
                attributes {
                    name
                    serialNumber
                    IsInstallationRequired
                    locationInstallationDate
                    kitchenArea
                    manufacturingDate
                    component_assets {
                        data {
                            id
                            attributes {
                                component {
                                    data {
                                        id
                                        attributes {
                                            name
                                            status
                                        }
                                    }
                                }
                            }
                        }
                    }
                    product {
                        data {
                            id
                            attributes {
                                description
                                name
                                images {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                                build {
                                    data {
                                        id
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                manufacturer {
                                    data {
                                        id
                                        attributes {
                                            business_info {
                                                data {
                                                    attributes {
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                part_models {
                                    data {
                                        id
                                        attributes {
                                            name
                                            images {
                                                data {
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                product_category {
                                    data {
                                        attributes {
                                            value
                                            product_classification {
                                                data {
                                                    attributes {
                                                        value
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                resources {
                                    data {
                                        id
                                        attributes {
                                            name
                                            type
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                    images {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
