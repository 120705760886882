import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { apiClient } from '../../../api/apiClient';
import { Input } from '../../../components/Input';
import { Modal } from '../../../components/Modal';
import { Select } from '../../../components/Select';
import { Dropzone } from '../../../components/Dropzone';
import { ToastContent } from '../../../components/ToastContent';
import { ManufacturersQuery } from '../../../api/common-queries/manufacturers';
import { ProductModelsQuery } from '../../../api/common-queries/pms';
import { BuildsQuery } from '../../../api/common-queries/builds';
import { ReactComponent as ColumnsIcon } from '../../../assets/icons/columns.svg';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { CreateAssetQuery, CreateQrCodeQuery, UpdateAssetQuery } from './query';
import styles from './styles.module.css';
import { Loader } from "../../../components/Loader";

enum ENUM_ASSET_KITCHENAREA {
  BOARDS = 'BOARDS',
  BREADING = 'BREADING',
  DINING_A = 'DINING_A',
  DINING_B = 'DINING_B',
  DRIVE_THRU = 'DRIVE_THRU',
  FRONT_OF_THE_LINE = 'FRONT_OF_THE_LINE',
  MULTI_PURPOSE_AREA = 'MULTI_PURPOSE_AREA',
  OFFICE = 'OFFICE',
}

export const CreateAssetModal = ({ editModalData, onClose }: any) => {
  const navigate = useNavigate();

  const [manufacturer, setManufacturer] = useState<string>(editModalData.manufacturerId || '');
  const [model, setModel] = useState<string>(editModalData.productModelId || '');
  const [build, setBuild] = useState<string>(editModalData.buildId || '');
  const [qrCode, setQrCode] = useState<string>('');
  const [name, setName] = useState<string>(editModalData.name || '');
  const [serial, setSerial] = useState<string>(editModalData.serialNumber || '');
  const [manufacturingDate, setManufacturingDate] = useState<string>(editModalData.manufacturingDate || '');
  const [isInstallationRequired, setIsInstallationRequired] = useState<boolean>(editModalData.isInstallationRequired || false);
  const [installationDate, setInstallationDate] = useState<string>(editModalData.installationDate || '');
  const [kitchenArea, setKitchenArea] = useState<string>(editModalData.kitchenArea || '');
  const [photo, setPhoto] = useState<File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: manufacturersData } = useQuery(ManufacturersQuery);
  const { data: productModelsData } = useQuery(ProductModelsQuery, { skip: !manufacturer, variables: { manufacturer: manufacturer } });
  const { data: buildsData } = useQuery(BuildsQuery);
  const [createAsset] = useMutation(CreateAssetQuery);
  const [updateAsset] = useMutation(UpdateAssetQuery);
  const [createQrCode] = useMutation(CreateQrCodeQuery);
  const manufacturers = manufacturersData?.manufacturers?.data || [];
  const productModels = productModelsData?.products?.data || [];
  const builds = buildsData?.builds?.data || [];

  const isEdit = editModalData?.type === 'edit';
  const actionName = isEdit ? 'Update' : 'Create';
  const actionDoneLabel = isEdit ? 'updated' : 'created';

  const onSubmit = async () => {
    setIsLoading(true);
    let photoId;
    if (photo) {
      const form = new FormData();
      form.append('files', photo);
      const res = await apiClient.post('/upload', form);
      photoId = res.data?.[0]?.id;
    }

    let qrCodeRes;
    if (qrCode) {
      try {
        qrCodeRes = await createQrCode({
          variables: {
            qrID: qrCode,
          },
        });
      } catch (e) {
        console.error("Failed to create QR code:", e);
      }
    }
    const newQrCodeId = qrCodeRes?.data?.createQRcode?.data?.id;

    const method = isEdit ? updateAsset : createAsset;
    const assetRes = await method({
      variables: {
        id: isEdit ? editModalData.id : undefined,
        name,
        product: model,
        serialNumber: serial,
        manufacturingDate: (manufacturingDate && manufacturingDate !== '–') ? manufacturingDate : undefined,
        IsInstallationRequired: isInstallationRequired,
        locationInstallationDate: isInstallationRequired ? installationDate : undefined,
        kitchenArea: kitchenArea ? kitchenArea : undefined,
        images: photoId ? [photoId] : undefined,
        q_rcodes: newQrCodeId ? [newQrCodeId] : undefined,
      },
    });
    const newAssetId = isEdit ? assetRes?.data?.updateAsset?.data?.id : assetRes?.data?.createAsset?.data?.id;
    toast(<ToastContent title={`Asset ${actionDoneLabel}!`} message={`Your new asset is successfully ${actionDoneLabel}`} />);
    navigate(`/asset/${newAssetId}`);
    setIsLoading(false);
    onClose();
  };

  if (!!editModalData) {
    return (
      <Modal onClose={onClose}>
        <div className={styles.modal}>
          <div className={styles.title}>
            <h1>{actionName} Asset</h1>
          </div>
          <div className={styles.content}>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <ColumnsIcon />
                Asset Details
              </div>
              <div className={styles.fieldsRow}>
                <div className={styles.fieldsColumn}>
                  <Select
                    value={manufacturer}
                    placeholder="Manufacturer"
                    options={manufacturers.map((manufacturer: any) => ({
                      key: manufacturer.id,
                      label: manufacturer.attributes.business_info?.data?.attributes?.name || `[ID ${manufacturer.id}]`
                    }))}
                    onChange={(newValue) => {
                      setManufacturer(newValue);
                      setModel('');
                    }}
                  />
                  <Select
                    value={model}
                    disabled={!manufacturer}
                    placeholder="Product Model"
                    options={productModels.map((pm: any) => ({
                      key: pm.id,
                      label: pm.attributes.name || `[ID ${pm.id}]`
                    }))}
                    onChange={(newValue) => {
                      setModel(newValue);
                    }}
                  />
                  <Input value={serial} onChange={(e) => setSerial(e.target.value)} placeholder="Serial Number" />
                  <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                  <Input
                    type='date'
                    value={manufacturingDate}
                    onChange={(e) => setManufacturingDate(e.target.value)}
                    placeholder="Manufacturing Date"
                  />
                </div>
                <div className={styles.fieldsColumn}>
                  <Select
                    value={isInstallationRequired ? 'yes' : 'no'}
                    placeholder="Installatiion Required"
                    options={[{ key: 'no', label: 'No' }, { key: 'yes', label: 'Yes' }]}
                    onChange={(newValue) => {
                      if (newValue === 'no') {
                        setInstallationDate('');
                      }
                      setIsInstallationRequired(newValue === 'yes');
                    }}
                  />
                  {isInstallationRequired && (
                    <Input
                      type='date'
                      value={installationDate}
                      onChange={(e) => setInstallationDate(e.target.value)}
                      placeholder="Installatiion Date"
                    />
                  )}
                  {!isEdit && <Input value={qrCode} onChange={(e) => setQrCode(e.target.value)} placeholder="QR Code" />}
                  <Select
                    value={build}
                    disabled={!builds.length}
                    placeholder="Build Name"
                    options={builds.map((build: any) => ({
                      key: build.id,
                      label: build.attributes.name || `[ID ${build.id}]`
                    }))}
                    onChange={(newValue) => {
                      setBuild(newValue);
                    }}
                  />
                  <Select
                    value={kitchenArea}
                    placeholder="Kitchen Area"
                    options={Object.keys(ENUM_ASSET_KITCHENAREA).map(ka => ({ key: ka, label: ka }))}
                    onChange={(newValue) => {
                      setKitchenArea(newValue);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.category}>
              <div className={styles.subCategory}>
                <ImageIcon />
                Images
              </div>
              <Dropzone value={photo} onChange={setPhoto} />
            </div>
            <div className={styles.bottomActions}>
              <button className={styles.cancelBtn} onClick={onClose}>
                Cancel
              </button>
              <button
                className={styles.createBtn} onClick={onSubmit}
                disabled={isLoading || !name || !manufacturer || !manufacturingDate || !model || !serial}
              >
                {isLoading ? <Loader small /> : actionName}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};
