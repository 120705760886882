import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Table from '../../components/Table/Table';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { CreatePMModal } from './CreatePMModal';
import { PlusFloatingButton } from '../../components/PlusFloatingButton';
import { RemovalConfirmationModal } from '../../components/RemovalConfirmationModal';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
// import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
// import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as OrganizationsIcon } from '../../assets/icons/organizations-icon.svg';
import { DeleteProductModelQuery, ProductModelsListQuery } from './query';
import styles from './styles.module.css';

export const ProductModelsPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [editModalData, setEditModalData] = useState<Record<string, string>>();
  const [promptToRemoveId, setPromptToRemoveId] = useState<string>();
  const { data, loading, refetch } = useQuery(ProductModelsListQuery, {
    variables: { page, search },
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const [deleteProductModel] = useMutation(DeleteProductModelQuery);

  const availableFilters = [
    { key: 'category', label: 'Category', values: [
        { key: 'Walk-in Cooler', label: 'Walk-in Cooler' },
        { key: 'Walk-in Freezer', label: 'Walk-in Freezer' },
        { key: 'Undercounter Refrigerator', label: 'Undercounter Refrigerator' },
    ]},
    { key: 'classification', label: 'Classification', values: [{ key: 'Cold Side', label: 'Cold Side' }, { key: 'Hot Side', label: 'Hot Side' }, { key: 'HVAC', label: 'HVAC' }, { key: 'Misc', label: 'Misc' }] },
  ];

  const rows = useMemo(
    () =>
      (data?.products?.data || []).map((product: any) => ({
        id: product.id,
        name: product.attributes?.name,
        previewImageUrl: product.attributes?.images?.data?.[0]?.attributes?.url,
        images: product.attributes?.images?.data ?? [],
        resources: product.attributes?.resources?.data ?? [],
        category: product.attributes?.product_category?.data?.attributes?.value,
        categoryId: product.attributes?.product_category?.data?.id,
        classification: product.attributes?.product_category?.data?.attributes?.product_classification?.data?.attributes?.value,
        classificationId: product.attributes?.product_category?.data?.attributes?.product_classification?.data?.id,
        manufacturerName: product.attributes?.manufacturer?.data?.attributes?.business_info?.data?.attributes?.name,
        manufacturerId: product.attributes?.manufacturer?.data?.id,
        assetAmount: product.attributes?.assets?.data?.length ?? '—',
        description: product.attributes?.description ?? '',
        height: product.attributes?.specification?.data?.attributes?.height,
        depth: product.attributes?.specification?.data?.attributes?.depth,
        width: product.attributes?.specification?.data?.attributes?.width,
        weight: product.attributes?.specification?.data?.attributes?.weight,
      })),
    [data]
  );

  /* const onEdit = (params: any) => {
    const entityData = rows?.find((row: any) => row.id === params.data.id);
    setEditModalData({ type: 'edit', ...(entityData || {}) });
  }; */

  const onDelete = async (id: any) => {
    await deleteProductModel({
      variables: { id },
    });
    refetch?.();
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={loading}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            onClick={(e: any) => {
              if (e.colDef?.field === 'actions') return;
              navigate(`/product-model/${e.data.id}`);
            }}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate(-1)}>
                  <BackArrowIcon />
                </button>
                <h1>Product Models</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Product Model',
                flex: 1,
                minWidth: 280,
                cellRenderer: (params: { data: { previewImageUrl: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.previewImageUrl ? <img className="product-image" src={params.data.previewImageUrl} alt="" /> : <OrganizationsIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
                pinned: 'left'
              },
              {
                field: 'category',
                headerName: 'Category',
                minWidth: 200,
                flex: 1,
                filterName: 'category_id',
              },
              {
                field: 'classification',
                headerName: 'Classification',
                width: 200,
                filterName: 'category_classification_id',
              },
              {
                field: 'manufacturerName',
                headerName: 'Manufacturer',
                width: 360,
                filterName: 'manufacturerName',
              },
              {
                field: 'assetAmount',
                headerName: 'Asset Amount',
                width: 360,
                filterName: 'assetAmount',
              },
              {
                field: 'actions',
                headerName: '',
                width: 120,
                filterName: 'actions',
                cellRenderer: (/*params: any*/) => (
                  <div className={styles.actionsField}>
                    {/*<EditIcon onClick={() => onEdit(params)} />
                    <CloseIcon onClick={() => setPromptToRemoveId(params.data.id)} />*/}
                  </div>
                ),
              },
            ]}
            filters={filters}
            rows={rows}
            availableFilters={availableFilters}
            paginationData={data?.products?.meta?.pagination}
            onChangePage={setPage as any}
          />
          <PlusFloatingButton onClick={() => setEditModalData({ type: 'new' })} />
          {!!editModalData && <CreatePMModal editModalData={editModalData} onClose={() => setEditModalData(undefined)} />}
          <RemovalConfirmationModal
            promptToRemoveId={promptToRemoveId}
            entityLabel='Product model'
            onRemove={onDelete}
            onClose={() => setPromptToRemoveId(undefined)}
          />
        </div>
      </div>
    </div>
  );
};
